import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import fonts from "../styles/fonts"
import Text from "src/components/Blocks/SmallText"

const PageContainer = styled.div`
  max-width: ${breakpoints.xxl};
  padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    padding-top: calc(6.6vmax + ${DESKTOP_HEADER_HEIGHT}px);
    display: grid;
    grid-template-columns: auto 1fr;
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: 1fr 2fr;
  }
`

const PageTitle = styled.h2`
  color: ${colors.royalBlue};
  line-height: 1.104;
  ${fonts.workSansBold};
  margin: 2rem 0;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    margin: 0;
    margin-right: 100px;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-right: 0;
  }
`

const Content = styled.div`
  margin-top: 83px;

  h4 {
    margin-top: 83px;

    :first-of-type {
      margin-top: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;

    h4 {
      font-size: calc(0.12vw + 1rem);
    }
  }
`

const FaqPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulSimplePage

  let sharingImage = false

  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <PageTitle>{page.pageTitle}</PageTitle>
        <Content>
          <Text content={page.content} references={page.content.references} />
        </Content>
      </PageContainer>
    </Layout>
  )
}

export default FaqPage

export const pageQuery = graphql`
  query FaqQuery {
    contentfulSimplePage(slug: { eq: "faqs" }) {
      ...simplePageFragment
    }
  }
`
